@import "vars.scss";
@import "mixin.scss";

.breadcrumb {
  padding-left: 10px;
  margin-bottom: 0;
  list-style: none;
  background-color: white;
  border-radius: 0;
  position: fixed;
  top: 114px;
  padding-top: 15px;
  width: 100%;
  z-index: 300;
  font-size: 16px;

  @include mobile {
    left: 0;
    top: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  content: "\203A" !important;
  color: black;
  font-size: 22px;
  line-height: 22px;
}

.breadcrumb {
  .breadcrumb-home {
    display: inline-block;
    background-image: url('/assets/images/home-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 14px;
    position: relative;
    top: 2px;
  }
}

.breadcrumb li a {
  color: $greenColor3 !important;
}

.breadcrumb > .active {
  a {
    color: $greenColor3 !important;
  }
  color: $greenColor3;
}