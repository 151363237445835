@import "vars.scss";
@import "mixin.scss";

.post-user-name {
  font-weight: bold;
  color: green;
}

.post-text {
  font-size: 18px;
  color: #666666;
  line-height: 1.5em;
  font-family: Brandon, sans-serif;

  img {
    //display: table;
    clear: both;
    margin: 5px 0;
    max-width: 100%;
  }

  a {
    @include link-green;
  }

  p {
    text-align: justify;
    margin: 0;
  }
}

.post-timestamp {
  margin-left: 10px;
  margin-right: 2px;
  font-family: Brandon, Verdana, Geneva, sans-serif;
  font-size: 13px;
  color: black;
}

.post-link-bullet {
  position: relative;
  font-size: 13px;
  margin-left: 11px;
  cursor: pointer;
  &:before {
    position: absolute;
    left: -13px;
    content: "\00A0 \00B7 \00A0";
  }
}

.socialButtonsContainer {
  margin-left: 20px;
}

.post__title {
  margin-top: 0;
  margin-bottom: 2px;
  color: #666;
  font: 25px/25px Brandon, sans-serif, Arial, Verdana, Hevletica;
  font-weight: bold;
}

.post-user-name-from {
  color: #969595;
  font-style: italic;
  font-size: 15px;
}

.post-answer {
  position: relative;
  border-top: 2px solid green;
  border-bottom: 2px solid #8adc41;
  margin-bottom: 15px;
  background-color: #E9F5E9;
  max-width: 710px;
  @include laptop {
    left: 20px;
  }
  
  &:before,
  &:after {
    content: "";
    z-index: 100;
    position: absolute;
    top: 0;

    bottom: 55px;
    display: block;
    width: 2px;
    height: calc(100% + 2px);
    @include border-gradient;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  @include mobile {
    z-index: 1;
  }
}

.post-answer--yellow {
  background-color: #fbfdab;
}

.post-answer--yellow  .post-answer__decorate-pic {
  background-image: url("/assets/images/answers-arrow--yellow.png");
}

// add new HTML-element because :before & :after already used
.post-answer__decorate-pic {
  z-index: 200; // must be bigger than :before element (decorative left borders)
  content: "";
  position: absolute;
  top: 45px;
  left: -9px;
  width: 11px;
  height: 34px;
  background-image: url("/assets/images/answers-arrow.png");
}

.post-answer__user-wrap {
  margin-bottom: -12px;
  display: flex;
  align-items: center;
}

.post-answer__avatar-wrap {
  display: inline-block;
  padding-left: 4px;
  position: relative;
  top: -3px;
  margin-right: 10px;
}

.post-answer__avatar-wrap img {
  width: 40px !important;
  height: 40px !important;
}

.main-post {
  .post-answer__avatar-wrap {
    display: inline-block;
    //padding-left: 4px;
    position: relative;
    top: auto;
    margin-right: 10px;
  }

  .post-answer__avatar-wrap img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;

    @include mobile {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

.post-answer__sub-answer {
  .post-answer {
    left: 15px;
  }
}
//.post-timestamp {
//  margin-bottom: 10px;
//}

.post-answer .post-timestamp {
  margin-left: 10px;
  margin-bottom: 3px;
  font-size: 13px;
  color: #555555;
}

.post-answer__text {
  font-family: Brandon, sans-serif, Arial, Verdana, Helvetica;
  font-size: 16px;
  color: #666666;
  line-height: 20px;
  padding: 10px 25px;
  padding-bottom: 9px;

  a {
    word-break: break-all;
  }
}

.post-answer img {
  width: 45px;
}

.post-answer__decorate-pic {
  @include mobile {
    display: none;
  }
}

.post__user-wrap {
  margin-top: 10px;
}

.main-post {
  .post__user-wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.post__author {
  color: $greenColor3;
  font-weight: bold;
  font-size: 15px;
  &:hover { color: darken($greenColor3, 7%); }
}

.post__author--anon {
  color: black;
  font-weight: 400;
  &:hover { color: black; }
}

.post__avatar-wrap {
  position: relative;
  top: -2px;
  display: inline-block;
}

.post__avatar-wrap img {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.main-post {
  .post__avatar-wrap img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;

    @include mobile {
      width: 30px;
      height: 30px;
    }
  }

  .med-history__view {
    background-color: $greenColor3;
    padding: 2px 5px;
    border: none;
    color: white;
    border-radius: 4px;
    font-weight: normal;
  }
}

.post__anon-img {
  width: 50px;
  height: 50px;
}

.post__anon {
  margin-left: 6px;
  margin-right: 10px;
  line-height: 50px;
  font-size: 15px;
  height: 50px;
  font-weight: 400;
  color: black;
}

.post__footer {
  margin-top: 9px;
}

.author_info {
  line-height: 54px;
  color: #969595;
  font-style: italic;
  font-size: 15px;
}

/*.post__wrap {
  padding-left: 10px;
}*/

.post__question-likes {
  position: relative;
  padding-left: 38px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #969595;
  &:after {
    content: "";
    position: absolute;
    top: -6;
    left: 20px;
    width: 14px;
    height: 17px;
    background: url(/assets/images/thumb_up.png) no-repeat;
  }
}

@include mobile { //Shame.jpg

  .post__user-wrap {
    text-align: left;
  }

  .post__avatar-wrap {
    display: block;
    //margin: 0 auto;
    width: 50px;
    float: left;
    top: auto;
  }

  .post__author {
    @include mobile {
      font-size: 12px;
    }
  }
  .author_info  { font-size: 13px; }

  .post-answer__user-wrap {
    margin-top: 14px;
    margin-bottom: 0;
  }

  .post-answer__sub-answer .post-answer { left: 0;  }

  .post-answer__avatar-wrap {
    float: left;
    padding-left: 0;
    margin-right: 10px;
    margin-left: 4px;
    //top: -12px;
  }

  .author_info {
    line-height: 1em;
  }

  .main-post {
    .post__user-wrap {
      text-align: left;
    }

    .post__avatar-wrap {
      display: block;
      //margin: 0 auto;
      width: 50px;
      float: left;
      top: auto;
    }

    .post-answer__avatar-wrap {
      float: none;
      display: block;
      top: 0;
      margin-top: 5px;
    }

    .post__anon {
      font-size: 13px;
      margin-right: 0;
    }

    .med-history__view {
      margin-left: 0;
      margin-top: 10px;
      font-size: 13px;
    }

    .author_info  {
      @include mobile {
        font-size: 12px;
      }
    }
  }
}

.closed-post {
  margin-bottom: 20px;
  margin-top: 20px;
  display: inline-block;
  border: 2px solid orange;
  padding: 5px;
  background-color: #fff1d8;
  text-align: center;
}

.post-text br {
  display: none;
}
.post-text{
  p {
    br {
      display: inline-block;
    }
  }
}




