@import "vars.scss";
@import "mixin.scss";

//////////////////////////////////////////////////// General styles ////////////////////////////////////////////////////

.description-box {
  font-size: 17px;
  margin-bottom: 33px;
  color: black;
  @include mobile {
    margin-top: 15px;
    margin-bottom: 32px;
  }
}

.description-box table {
  @include mobile {
    width: 100% !important;
  }
}

.description-box td {
  @include mobile {
    display: block;
    width: 100% !important;
    margin: 0 auto;
  }
}

//////////////////////////////////////////////// First table text-block ////////////////////////////////////////////////

.description-box table:first-child {
  @include laptop {
    margin-bottom: 9px;
  }
}

//Style for description-box text-block
.description-box table:first-child td:first-child {
  @include laptop {
    padding: 5px;
    padding-right: 8px;
  }
  @include mobile {
    //height: 435px !important;
    //height: 530px !important;
    margin-bottom: -40px;
  }
}
.description-box table:first-child td:last-child {
  @include mobile {
    //height: 200px !important;
  }
}

.description-box table:first-child td:first-child p {
  margin: 0;
  line-height: 1.3em;
  @include mobile {
    text-align: center !important;
  }
}

//Style for text-block donate
.description-box table:first-child form input {
  margin-bottom: -1px;
  padding: 2px 4px;
  border: 1px solid #B8C7D9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  @include mobile {
    display: block;
    margin: 0 auto;
  }
}

.description-box form {
  @include mobile {
    margin-top: 15px;
    margin-right: 8px;
  }
}

//Style for text-block donate link
.description-box table:first-child form + p {
  @include mobile {
    margin-top: 17px !important;
  }
}

////////////////////////////////////////////////// First table image //////////////////////////////////////////////////

.description-box table:first-child td:nth-child(2) {
  @include mobile {
    margin-bottom: -36px;
  }
}

.description-box table:first-child td:nth-child(2) p {
  text-align: center !important;
  @include mobile {
    margin-left: 10px;
  }
}

.description-box table:first-child img {
  position: relative;
  bottom: -4px;
  left: -1px;
  float: none !important;
  margin: 0 auto !important;
  @include mobile {
    display: block;
    width: 100% !important;
  }
}

/////////////////////////////////////////////////// Top posts styles ///////////////////////////////////////////////////

.description-box > p { //Top post title
  margin-left: 5px;
  margin-bottom: -2px;
  @include mobile {
    margin-top: 40px;
  }
}

.description-box table:nth-child(3)  {
  margin-bottom: 2px;
  margin-left: 5px;
}


.description-box table:nth-child(3) td {
  @include mobile {
    margin-bottom: 3px;
  }
}

.description-box table img {
  @include mobile {
    display: block;
    height: 143px;
    width: 96% !important;
  }
}

.top-post__title {
  margin-bottom: 1px;
  margin-left: 1px;
  @include mobile {
    margin-bottom: -1px;
    margin-left: 9px;
    margin-top: 5px;
  }
}

.top-post__table{
  margin-left: 5px;
}

